import React, {useState} from 'react'
import Layout from "../components/Layout"
import { Container, Row, Col, Card, Button, Figure  } from 'react-bootstrap'
import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby' 
import CareerCarousel from '../components/CareerCarousel';
import ShareButtons from '../components/sharebuttons';
import { format} from 'date-fns'
import { Seo } from '../components/Seo';
import metaImageOg from '../assets/images/apply-og.jpg';
import { useLocation } from "@reach/router"
// import career from 'https://assets.sogody.co.uk/sogody/career.png';

export default function() {
    const data = useStaticQuery(query);
    const {allContentfulJobPositions:{
        nodes:careers
    }} = data
    const title = "Join our talented community at Sogody";

    const [copied, setCopied] = useState(false);

    const pathLocation = useLocation();
    const url = pathLocation.href;
    // console.log("URL : " + url);
    // const url= typeof window !== "undefined" ? window.location.href : "";

    const today = new Date();
    const total= careers.filter(careers=> new Date(careers.date) >= today).length;
    // const dateNew = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear() ;
    return (
    <Layout>
        <Seo title="Careers - Sogody" description={title} image={'https://sogody.com'+metaImageOg} url={url}/>
        <div className="careers-banner">
            <Container> 
                <p className="banner-title">{title}</p>
                <img src={'https://sogody.com'+metaImageOg} style={{display:"none"}}/>
            </Container>
        </div>
        <div className="careers-positions">
        <Container className="up-container">
            <div className="positions">
            { total == 0  ?  
            <Card className="no-career-card">
                <Card.Body>
                   <h2 class="title">Sorry, there are no positions available at this time</h2>
                   <p class="subtitle">However, we are always keen to meet energetic and talented professionals who would like to join our team. If you wish to be considered for any future positions, please send your CV and/or a link to your portfolio at:</p>
                   <a class="email" href="mailto:info@sogody.com">info@sogody.com</a>
                </Card.Body>
            </Card> :  
            <> 
                <h2 className="header-title">Open positions</h2> 
                <hr></hr>
                <Row>
                {careers.filter(career => new Date(career.date) >= today).map((cr)=>{
                return <>
                    <Col xs="12" md="12" lg="12">
                        <Card className="career-card">
                        <a href={`/careers/${cr.slug}/`}> 
                        <Card.Body>
                            <Row>  
                                <Col xs={12} md={7} lg={7} className="left-col">
                                <h1 className="position-title">{cr.positionTitle}</h1>
                                <p className="position-location">{cr.location} - dl: {format(new Date(cr.date), "dd.MM.yyyy")}</p>

                                </Col>
                                <Col className="right-col">
                                    <Button href={`/careers/${cr.slug}/`} className="tertiary-btn-s">{cr.btnText} <Figure className="read-more">
                                        <Figure.Image   src={require('../assets/images/next_service.png')}  />
                                    </Figure></Button>
                                </Col>
                            </Row>
                        </Card.Body>
                        </a>
                        </Card>
                    </Col>
                    </>})}
                </Row> 
            </>
            }
            </div>
            <div className='career-benefits'>
                <h1 className='title'>Benefits of working with us</h1>
                <Row>
                    <Col>
                        <img className='career-img' src="https://assets.sogody.co.uk/sogody/rocket.png"></img>
                        <p>Challenging Projects</p>
                    </Col>
                    <Col>
                        <img className='career-img' src="https://assets.sogody.co.uk/sogody/technologies.png"></img>
                        <p>Latest Technologies</p>
                    </Col>
                    <Col>
                        <img className='career-img' src="https://assets.sogody.co.uk/sogody/community.png"></img>
                        <p>Like-minded Community</p>
                    </Col>
                    <Col>
                        <img className='career-img' src="https://assets.sogody.co.uk/sogody/career.png"></img>
                        <p>Career Advancement</p>
                    </Col>
                    <Col>
                        <img className='career-img' src="https://assets.sogody.co.uk/sogody/engagement.png"></img>
                        <p>Employee Engagement</p>
                    </Col>
                    <Col>
                        <img className='career-img' src="https://assets.sogody.co.uk/sogody/mentorship.png"></img>
                        <p>Mentorship</p>
                    </Col>
                </Row>
            </div>
            <ShareButtons url={url ? url : " "} title={title} summary={title}  body={title + "\n\n" + "Visit: " + url +"\n"}/>
        </Container>
        </div>
        <CareerCarousel/>
    </Layout>
    )
}

export const query = graphql`
{
    allContentfulJobPositions(sort: {fields: updatedAt},filter: {node_locale: {eq: "en-US"}}) {
        nodes {
        positionTitle
        date
        slug
        location
        btnText
        }
        totalCount,
    }
}
`

